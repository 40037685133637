import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import { createUseStyles } from 'react-jss'
import SEO from "../components/seo"

const useStyles = createUseStyles({
    layout: {
        'padding': '1rem',
        'min-height': '100vh',
    },
    link: {
        color: 'white',
        textDecoration: 'none',
    }
})

// TODO: Make a component to show the article "summary"
//  IDEA: Link only the name of the article
//        Second line should have date and the category it belongs to
export default function Blog({ data }) {
    const classes = useStyles()
    return (
        <Layout style={classes.layout}>
            <SEO title="Articles" />
            <div>
                {data.allMarkdownRemark.edges.map(({ node }) => (
                    <div key={node.id}>
                        <Link
                            className={classes.link}
                            to={node.fields.slug}
                        >
                            <p>
                                {node.frontmatter.title}{" "}
                                — {node.frontmatter.date}
                            </p>
                        </Link>
                    </div>
                ))}
            </div>
        </Layout>
    )
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: {frontmatter: {category: {in: "Article"}}}) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            category
          }
          fields {
              slug
          }
          excerpt
        }
      }
    }
  }
`
